import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/Header/Header';
import Page from './views/Page/Page';
import Footer from './components/Footer/Footer';

import './styles/main.scss';

ReactDOM.render(
  <React.StrictMode>
    <main className="app">
      <Router>
        <Header />
        <Page />
        <Footer />
      </Router>
    </main>
  </React.StrictMode>,
  document.getElementById('root')
);
