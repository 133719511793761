import React, { Fragment, useEffect } from "react";

import "../Home/home.scss";

export default function Discover() {
  useEffect(() => {
    document.title = "Discover | MTG Discover: Discover random Magic: The Gathering Cards!";

    const url = new URL(window.location);
    const uuid = url.searchParams.get("uuid");

    setTimeout(() => {
      window.location.replace(`https://apps.apple.com/us/app/mtg-discover/id1534340485?uuid=${uuid}`);
    }, 3000);

  }, []);

  return (
    <Fragment>
      <div className="home">
        <div className="home-description">
          <div className="home-icon">
            <img src="/images/icon.png" alt="mtg discover app icon" />
            <h1>MTG Discover</h1>
          </div>
          <h2>Now redirecting you to the App Store</h2>
          <p>
            Please wait...
          </p>
        </div>
      </div>
    </Fragment>
  );
}
