import React, { useEffect } from "react";

import "./home.scss";

export default function Home() {
  useEffect(() => {
    document.title = "MTG Discover: Discover random Magic: The Gathering Cards!";
  }, []);

  return (
    <div className="home">
      <div className="home-description">
        <div className="home-icon">
          <img src="/images/icon.png" alt="mtg discover app icon" />
          <h1>MTG Discover</h1>
        </div>
        <h2>Discover random Magic: The Gathering cards!</h2>
        <p>
          MTG Discover lets you view random Magic: The Gathering cards organically. Features include:
        </p>
        <ul>
          <li>Card imagery and details</li>
          <li>Link to more card information</li>
          <li>Purchasing link</li>
          <li>Card favorites</li>
          <li>Card searching</li>
        </ul>
        <div className="home-action">
          <div className="home-buttons">
            <a
              href="https://apps.apple.com/us/app/mtg-random/id1534340485"
              rel="noopener noreferrer"
              target="_blank">
              <img
                src="./images/button-app-store.svg"
                alt="Download on iOS App Store"
                title="Download on iOS App Store"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="home-image">
        <img src="/images/phone.jpg" alt="phones using the application" />
      </div>
    </div>
  );
}
