import React from 'react';
import { Link } from 'react-router-dom';

import './header.scss';

export default function Header() {
  return (
    <header>
      <nav>
        <Link className="logo-link" to="/">
          <p>MTG Discover</p>
        </Link>
      </nav>
    </header>
  );
}
