import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from '../Home/Home';
import Privacy from '../Privacy/Privacy';
import Discover from '../Discover/Discover';

import './page.scss';

export default function Page() {
  return (
    <section className="page">
      <div className="page-body">
        <div className="page-body--content">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/discover" component={Discover} />
            <Route component={() => {
              window.location.replace('/');
            }} />
          </Switch>
        </div>
      </div>
    </section>
  );
}
