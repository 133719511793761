import React, { useEffect } from "react";

export default function Privacy() {
  useEffect(() => {
    document.title = "Privacy Policy | MTG Discover: Discover random Magic: The Gathering cards!";
  }, []);

  return (
    <div className="page-body--legal">
      <h1>Privacy Policy</h1>
      <p>
        <strong>Last Updated: April 11, 2022</strong>
      </p>
      <p>
        This Service ("MTG Discover" and "mtgdiscover.app") is provided at no cost and is intended
        for use as is. This Service does not collect any Personal Identifiable Information, device
        data, advertising data or any other types of data.
      </p>
      <h2>External Links</h2>
      <p>
        This Service contains links to other sites that you may choose to visit. If you visit an
        external link, you will be redirected to that site. Note that any such external links are
        not operated by this Service. Therefore, it is strongly advised that you review the
        Privacy Policy of any site that you may visit. This Service has no control over and assumes
        no responsibility for the Privacy Policies, Terms of Use, content or practices of any
        external link or its services.
      </p>
      <h2>Changes to This Privacy Policy</h2>
      <p>
        This Service may update its Privacy Policy from time to time. Thus, you may review this page
        periodically for any changes.
      </p>
      <h2>Contact</h2>
      <p>
        If you have any questions regarding this Service's Privacy Policy, please contact the
        following email address: <a href="mailto:lakatos.eric@gmail.com">lakatos.eric@gmail.com</a>.
      </p>
    </div>
  );
}
