import React from 'react';
import { Link } from 'react-router-dom';

import './footer.scss';

export default function Footer() {
  // Set the copyright year
  const getYearRange = () => {
    const firstYear = 2020;
    const currentYear = Number(new Date().getFullYear());
    const yearRange = currentYear === firstYear ? currentYear : `${firstYear} - ${currentYear}`;
    return yearRange;
  };

  return (
    <footer>
      <div>
        <nav>
          <ol className="footer-links">
            <li>
              <Link className="link" to="/privacy">
                Privacy Policy
              </Link>
            </li>
          </ol>
          <ol>
            <li>
              &copy;Eric Lakatos <span id="year">{getYearRange()}</span> where
              applicable.
            </li>
            <li>All Rights Reserved.</li>
          </ol>
        </nav>
      </div>
    </footer>
  );
}
